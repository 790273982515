.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    padding-left: 5px;
    padding-right: 5px;
}

.row {
    margin-left: -5px;
    margin-right: -5px;
}

.col {
    padding-left: 5px;
    padding-right: 5px;
}

.vert-row {
    margin-top: -5px;
    margin-bottom: -5px;
}

.vert-col {
    margin-top: 5px;
    margin-bottom: 5px;
}