$borderColour: #c4c4c4;

body .b-light {
    $borderColour: #eae7e6;
}

body .bl-xs {
    border-left: 1px solid $borderColour;
}

body .br-xs {
    border-right: 1px solid $borderColour;
}

body .bt-xs {
    border-top: 1px solid $borderColour;
}

body .bb-xs {
    border-bottom: 1px solid $borderColour;
}

body .b-xs {
    border: 1px solid $borderColour;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    body .bl-sm {
        border-left: 1px solid $borderColour;
    }

    body .br-sm {
        border-right: 1px solid $borderColour;
    }

    body .bt-sm {
        border-top: 1px solid $borderColour;
    }

    body .bb-sm {
        border-bottom: 1px solid $borderColour;
    }

    body .b-sm {
        border: 1px solid $borderColour;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    body .bl-md {
        border-left: 1px solid $borderColour;
    }

    body .br-md {
        border-right: 1px solid $borderColour;
    }

    body .bt-md {
        border-top: 1px solid $borderColour;
    }

    body .bb-md {
        border-bottom: 1px solid $borderColour;
    }

    body .b-md {
        border: 1px solid $borderColour;
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    body .bl-lg {
        border-left: 1px solid $borderColour;
    }

    body .br-lg {
        border-right: 1px solid $borderColour;
    }

    body .bt-lg {
        border-top: 1px solid $borderColour;
    }

    body .bb-lg {
        border-bottom: 1px solid $borderColour;
    }

    body .b-lg {
        border: 1px solid $borderColour;
    }
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    body .bl-xl {
        border-left: 1px solid $borderColour;
    }

    body .br-xl {
        border-right: 1px solid $borderColour;
    }

    body .bt-xl {
        border-top: 1px solid $borderColour;
    }

    body .bb-xl {
        border-bottom: 1px solid $borderColour;
    }

    body .b-xl {
        border: 1px solid $borderColour;
    }
}