// Bootstrap colour overrides
$primary: #86A0C5;
$secondary: #4A8DD8;
$light: #F8F8F6;
$dark: #BDBDBD;
$info: #A280B9;
$danger: #CD4C4C;
$warning: #F4C452;
$success: #74C09A;
$darkRed:  #670001;
$lightGray: #ededed;
$darkGray: #545454;
$lightDark: #888888;
$warningRed: #950104;

body .bg-dark-red {
    background: $darkRed;
}

body .text-dark-red {
    color: $darkRed;
}

body .bg-warning-red {
    background: $warningRed;
}

body .text-warning-red {
    background: $warningRed;
}

body .bg-light-gray {
    background: $lightGray;
}

body .text-light-gray {
    color: $lightGray;
}

body .bg-dark-gray {
    background: $darkGray;
}

body .text-dark-gray {
    color: $darkGray;
}