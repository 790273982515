@import '../../css/colours.scss';

#content-container {
    margin-top: 0.5rem;
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    transition: transform 0.3s;

    overflow-y: auto;
    overflow-x: none;
    -webkit-overflow-scrolling: touch;

    &.toggled {
        transform: translateX(+100%);
    }

    /* WIDTH */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* TRACK */
    &::-webkit-scrollbar-track {
        display: none;
    }

    /* HANDLE */
    &::-webkit-scrollbar-thumb {
        background: #939598;
    }

    /* HANDLE ON HOVER */
    &::-webkit-scrollbar-thumb:hover {
        background: #939598;
    }

    /* FIREFOX STYLING */
    scrollbar-color: #545454 #939598;
    scrollbar-width: thin;
}

@media (min-width: 576px) {
    #content-container {
        width: calc(100% - 250px);
        max-width: calc(100% - 250px);
        height: calc(100% - 152px - 1rem);
        max-height: calc(100% - 152px - 1rem);
        margin-left: 250px;
    }
}

@media (min-width: 992px) {
    #content-container.with-sidebar-right {
        width: calc(100% - 500px);
        max-width: calc(100% - 500px);
    }
}