@import '../../css/colours.scss';

#sidebar-right {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 50;
    width: 250px;
    margin-top: calc(152px + 0.5rem);
    height: calc(100% - 152px - 1rem);
    max-height: calc(100% - 152px - 1rem);
    transition: transform 0.3s;
    background: #545454;

    /* Scrolling properties */
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    /* WIDTH */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* TRACK */
    &::-webkit-scrollbar-track {
        display: none;
    }

    /* HANDLE */
    &::-webkit-scrollbar-thumb {
        background: #939598;
    }

    /* HANDLE ON HOVER */
    &::-webkit-scrollbar-thumb:hover {
        background: #939598;
    }

    /* FIREFOX STYLING */
    scrollbar-color: #545454 #939598;
    scrollbar-width: thin;
}