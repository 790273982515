@import '../../css/colours.scss';

#admin {
    .admin-button {
        height: 100px;

        .admin-button-icon {
            font-size: 2rem;
        }
    }

    @media (min-width: 992px) {
        .admin-button {
            height: 150px;
        }
    }
}