// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    body, body .tox {
        font-size: 0.875rem;
    }

    body .MuiInputBase-root,
    body .MuiMenuItem-root,
    body .MuiFormLabel-root {
        font-size:0.875rem;
    }
}

body .textfield-rounded-0 {
    div {
        border-radius: 0;
    }
}