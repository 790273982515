#topbar {
    height: 60px;
    z-index: 100;
    background-color: black;
    width: 100%;

    .oberin-logo {
        height: 50px;
    }
}

.topbar-background {
    background-image: url('../../assets/headerback.jpg');
}

@media (min-width: 576px)
{
    #topbar {
        height: 152px;

        
    }
}