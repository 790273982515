.first-mobile-title {
    margin-top: 0.5rem;
}

.forum-category-link, .forum-category-link:visited, .forum-category-link:hover {
    text-decoration: underline;
    color: black;
}

.post-left-side {
    width: 150px;
    min-width: 150px;
}

.mobile-image-post-avatar {
    img {
        max-height: 50px;
    }
}