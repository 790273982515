@import '../../css/colours.scss';

#sidebar {
    position: fixed;
    top: 0;
    z-index: 50;
    margin-top: 60px;
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    transition: transform 0.3s;
    background: #545454;

    /* Scrolling properties */
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    /* Mobile properties */
    width: 100%;
    left: -100%;

    /* WIDTH */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* TRACK */
    &::-webkit-scrollbar-track {
        display: none;
    }

    /* HANDLE */
    &::-webkit-scrollbar-thumb {
        background: #939598;
        // background: $darkRed;
    }

    /* HANDLE ON HOVER */
    &::-webkit-scrollbar-thumb:hover {
        background: #939598;
        // background: $darkRed;
    }

    /* FIREFOX STYLING */
    scrollbar-color: #545454 #939598;
    // scrollbar-color: #545454 $darkRed;
    scrollbar-width: thin;

    .sidebar-list {
        margin: 0;
        padding-inline-start: 20px;
        // padding: 0;
        // list-style-type: 
    }
}

@media (max-width: 576px) {
    #sidebar {
        /*¨Toggle sidebar */
        &.toggled {
            transform: translateX(+100%);
        }
    }
}

/* Desktop properties */
@media (min-width: 576px) {
    #sidebar {
        margin-top: calc(152px + 0.5rem);
        width: 250px;
        left: 0px;
        height: calc(100% - 152px - 1rem);
        max-height: calc(100% - 152px - 1rem);
    }
}